import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Torn01 from "../torn/torn01";

export interface FooterProps {
  className?: string;
}

export type FooterQueryData = {
  openingHours: {
    pickup: {
      day: string;
      hours: string;
    }[];
    shop: {
      day: string;
      hours: string;
    }[];
  };
  navigation: {
    footer_col_1: {
      title: string;
      links: {
        label: string;
        slug: string;
        active: boolean;
        external: boolean;
      }[];
    };
    footer_col_2: {
      title: string;
      links: {
        label: string;
        slug: string;
        active: boolean;
        external: boolean;
      }[];
    };
    footer_col_3: {
      title: string;
      links: {
        label: string;
        slug: string;
        active: boolean;
        external: boolean;
      }[];
    };
    meta: {
      label: string;
      slug: string;
      active: boolean;
    }[];
  };
};

const Footer = ({ className }: FooterProps) => {
  const {
    navigation: { footer_col_1, footer_col_2, footer_col_3, meta },
    openingHours: { pickup: openingHoursPickup, shop: openingHoursShop },
  }: FooterQueryData = useStaticQuery(query);

  return (
    <div className="z-20 footer">
      <Torn01 className="text-stone-700" />
      <div className={classNames(["bg-stone-700", className])}>
        <div className="grid grid-cols-12 py-8 overflow-hidden container-7xl gap-x-0 sm:gap-x-12 gap-y-8 sm:px-12">
          <div className="col-span-12 pt-4">
            <h3 className="mb-1 text-2xl font-bold text-dark">
              Willkommen auf Michels Gemüsehof.
            </h3>
            <p className="text-sm opacity-75 text-dark">
              Der Gemüsegarten auf Basis gemeinschaftsgetragener Landwirtschaft
              in der Region Braunschweig, Hildesheim, Wolfenbüttel und
              Salzgitter.
            </p>
          </div>
          <hr className="col-span-12 border-dotted opacity-25 xl:hidden" />
          <div className="grid grid-cols-6 col-span-12 gap-y-6 sm:gap-y-0 sm:gap-x-16 lg:col-span-12 xl:gap-x-0 xl:gap-y-6 xl:col-span-3">
            <div className="col-span-6 space-y-3 sm:col-span-3 xl:col-span-6">
              <h4 className="text-xs font-bold uppercase opacity-60 text-dark tracking-widest-2">
                Abholzeiten Abo-Kiste
              </h4>
              <dl className="grid grid-cols-3 text-sm text-dark gap-y-2">
                {openingHoursPickup.map(({ day, hours }) => (
                  <React.Fragment key={day}>
                    <dt className="col-span-1">{day}</dt>
                    <dd className="col-span-2 text-right">{hours}</dd>
                  </React.Fragment>
                ))}
              </dl>
            </div>
            <div className="col-span-6 space-y-3 sm:col-span-3 xl:col-span-6">
              <h4 className="text-xs font-bold uppercase opacity-60 text-dark tracking-widest-2">
                Öffnungszeiten Hofladen
              </h4>
              <dl className="grid grid-cols-3 text-sm text-dark gap-y-2">
                {openingHoursShop.map(({ day, hours }) => (
                  <React.Fragment key={day}>
                    <dt className="col-span-1">{day}</dt>
                    <dd className="col-span-2 text-right">{hours}</dd>
                  </React.Fragment>
                ))}
              </dl>
            </div>
          </div>
          <hr className="col-span-12 border-dotted opacity-25 xl:hidden" />
          <div className="grid grid-cols-12 col-span-12 border-opacity-25 border-dotted xl:col-span-9 gap-y-12 sm:gap-y-0 sm:gap-x-12 xl:pl-12 xl:border-l border-l-stone-200">
            <div className="col-span-12 space-y-2 sm:col-span-4">
              <h4 className="text-xs font-bold uppercase opacity-60 text-dark tracking-widest-2">
                {footer_col_1.title}
              </h4>
              <nav>
                <ul>
                  {footer_col_1.links.map(
                    (link) =>
                      link.active && (
                        <li key={link.label}>
                          <Link
                            to={link.slug}
                            className="text-sm text-dark font-regular"
                          >
                            {link.label}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </nav>
            </div>
            <div className="col-span-12 space-y-2 sm:col-span-4">
              <h4 className="text-xs font-bold uppercase opacity-60 text-dark tracking-widest-2">
                {footer_col_2.title}
              </h4>
              <nav>
                <ul>
                  {footer_col_2.links.map(
                    (link) =>
                      link.active && (
                        <li key={link.label}>
                          <Link
                            to={link.slug}
                            className="text-sm text-dark font-regular"
                          >
                            {link.label}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </nav>
            </div>
            <div className="col-span-12 space-y-2 sm:col-span-4">
              <h4 className="text-xs font-bold uppercase opacity-60 text-dark tracking-widest-2">
                {footer_col_3.title}
              </h4>
              <nav>
                <ul>
                  {footer_col_3.links.map(
                    (link) =>
                      link.active && (
                        <li key={link.label}>
                          <a
                            href={link.slug}
                            className="text-sm text-dark font-regular"
                            target={link.external ? "_blank" : "_self"}
                          >
                            {link.label}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-stone-900">
        <div className="flex flex-col justify-between gap-4 py-4 sm:flex-row container-7xl">
          <p className="text-sm text-stone-400">
            Michels Gemüsehof
            {`, © ${new Date(Date.now()).getFullYear()}`}
          </p>
          <nav>
            <ul className="flex flex-col gap-2 sm:gap-6 sm:flex-row">
              {meta.map(
                (m) =>
                  m.active && (
                    <li key={m.slug}>
                      <Link to={m.slug} className="text-sm text-stone-400">
                        {m.label}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query FooterQuery {
    openingHours: openingHoursJson {
      pickup {
        day
        hours
      }
      shop {
        day
        hours
      }
    }

    navigation: navigationJson {
      footer_col_1 {
        title
        links {
          label
          slug
          active
          external
        }
      }
      footer_col_2 {
        title
        links {
          label
          slug
          active
          external
        }
      }
      footer_col_3 {
        title
        links {
          label
          slug
          active
          external
        }
      }
      meta {
        label
        slug
        active
      }
    }
  }
`;

export default Footer;
